import { useEffect, useContext, useRef } from 'react'
import UserContext from '../context/UserContext'
import {
	GoogleAuthProvider,
	FacebookAuthProvider,
	signInWithPopup,
	AuthProvider,
	createUserWithEmailAndPassword,
	sendEmailVerification,
	signInWithEmailAndPassword,
	signOut,
	sendPasswordResetEmail,
	onAuthStateChanged,
	updateProfile,
	deleteUser,
	getAdditionalUserInfo,
} from 'firebase/auth'
import { doc, onSnapshot, Unsubscribe } from 'firebase/firestore'
import { create } from 'superstruct'
import { CHARACTERS, User } from '@istvan-kreisz/hnsw-library'
import useFirebaseSetup from './useFirebaseSetup'
import { useFingerprintAndIP } from './useFingerprintAndIP'
import { logEvent } from 'firebase/analytics'

export default function useAuthenticator({
	fetchCanCreateUser = false,
}: { fetchCanCreateUser?: boolean } = {}) {
	const canCreateUser = useRef<boolean | undefined>(undefined)
	const authListener = useRef<Unsubscribe>()
	const signupUsername = useRef<string | undefined>(undefined)
	const signupCharacterId = useRef<string | undefined>(undefined)
	const signupReferralCode = useRef<string | undefined>(undefined)
	const signupEmailOptIn = useRef<boolean | undefined>(undefined)
	const didFetchCanCreateUser = useRef(false)

	const { firestore, auth, getFunction, analytics } = useFirebaseSetup([
		'firestore',
		'auth',
		'functions',
		'analytics',
	])
	const canCreateUserFunc = getFunction('canCreateUserGen2')
	const userSignedUpFunc = getFunction('userSignedUpGen2')

	const { userContext, setUserContext } = useContext(UserContext)

	const { setIPIfNeeded, updateFpIfNeeded } = useFingerprintAndIP()

	const newUserToDeleteId = useRef<string | undefined>(undefined)

	const defaultStatistics = {
		allTime: {
			wins: 0,
			bangOns: 0,
			level: 0,
			kingofTheWorldSeconds: 0,
		},
		currentSeason: {
			wins: 0,
			bangOns: 0,
			level: 0,
			kingofTheWorldSeconds: 0,
		},
		tournament: {
			highestScore: 0,
			startedDate: undefined,
		},
	}

	const getSavedUser = (): User | undefined => {
		const savedUser = localStorage.getItem('user')
		if (savedUser) {
			try {
				const user = create(JSON.parse(savedUser), User)
				return user
			} catch (err) {}
		}
		return undefined
	}

	const resetUserState = () => {
		setUserContext(null)
		localStorage.removeItem('user')
		signupUsername.current = undefined
		signupCharacterId.current = undefined
	}

	useEffect(() => {
		let unsubscribe: Unsubscribe
		if (userContext?.id && firestore) {
			const docRef = doc(firestore, 'users', userContext?.id)
			unsubscribe = onSnapshot(docRef, (snapshot) => {
				try {
					const _user = snapshot.data()
					const user = create(_user, User)
					user.id = user.id || userContext.id
					user.email = user.email || userContext.email
					setUserContext(user)
					localStorage.setItem('user', JSON.stringify(user))
				} catch (err) {
					console.error(err)
				}
			})
		}

		return () => {
			if (unsubscribe) {
				unsubscribe()
			}
		}
	}, [userContext?.id, firestore])

	useEffect(() => {
		;(async () => {
			if (!canCreateUserFunc) return
			if (typeof canCreateUser.current === 'boolean') return
			if (!fetchCanCreateUser) return
			if (didFetchCanCreateUser.current) return
			didFetchCanCreateUser.current = true

			try {
				await Promise.all([updateFpIfNeeded(), setIPIfNeeded()])
			} catch {}

			const fingerprint = localStorage.getItem('_p_f_')
			const country = localStorage.getItem('_c_')
			const ip = localStorage.getItem('_i_')
			const response = await canCreateUserFunc({ fingerprint, ip, country })

			if (typeof response.data === 'boolean') {
				canCreateUser.current = response.data
			}
		})()
	}, [canCreateUserFunc, fetchCanCreateUser, setIPIfNeeded, updateFpIfNeeded])

	const signInWithProvider = async (
		providerName: 'google' | 'facebook',
		username?: string,
		characterId?: string,
		referralCode?: string,
		emailOptIn?: boolean
	) => {
		signupUsername.current = username
		signupCharacterId.current = characterId
		signupReferralCode.current = referralCode
		signupEmailOptIn.current = emailOptIn

		let provider: AuthProvider
		if (providerName === 'google') {
			provider = new GoogleAuthProvider()
			;(provider as GoogleAuthProvider).setCustomParameters({ prompt: 'select_account' })
		} else if (providerName === 'facebook') {
			provider = new FacebookAuthProvider()
		}
		if (!provider) {
			return
		}

		try {
			const result = await signInWithPopup(auth, provider)
			const { isNewUser } = getAdditionalUserInfo(result)

			if (isNewUser && canCreateUser.current === false) {
				try {
					newUserToDeleteId.current = result.user.uid
					logOut(true)
					setTimeout(() => {
						deleteUser(result.user)
					}, 2500)
					logEvent(analytics, 'block_signup', { provider: providerName })
					throw new Error('Not allowed')
				} catch {}
			}
		} catch (error) {
			console.log('>>>>>>>>>>>>>>> error')
			console.error(error)
		}
	}

	const signUpWithEmailAndPassword = async (
		email: string,
		password: string,
		username: string,
		characterId: string,
		emailOptIn: boolean,
		referralCode?: string
	) => {
		signupUsername.current = username
		signupCharacterId.current = characterId
		signupReferralCode.current = referralCode
		signupEmailOptIn.current = emailOptIn

		try {
			if (canCreateUser.current === false) {
				logEvent(analytics, 'block_signup', { provider: '' })
				throw new Error('Not allowed')
			}

			await createUserWithEmailAndPassword(auth, email, password)
			await signInWithEmailAndPassword(auth, email, password)
		} catch (err) {
			console.log('Error creating account')
			console.error(err)
			throw err
		}
	}

	const sendVerificationEmail = async () => {
		return sendEmailVerification(auth.currentUser)
	}

	const signInWithEmailAndPw = (email: string, password: string) => {
		signupUsername.current = undefined
		signupCharacterId.current = undefined
		signupReferralCode.current = undefined
		signupEmailOptIn.current = undefined

		return signInWithEmailAndPassword(auth, email, password)
	}

	const logOut = async (forced: boolean = false) => {
		try {
			await signOut(auth)
			resetUserState()
		} catch (err) {
			console.log('error signing out')
			console.error(err)
			if (forced) {
				resetUserState()
			}
		}
	}

	const sendPwResetEmail = (email: string) => {
		return sendPasswordResetEmail(auth, email)
	}

	useEffect(() => {
		if (!auth || !setUserContext) return
		if (authListener.current) return

		authListener.current = onAuthStateChanged(auth, async (user) => {
			if (user) {
				// new user setup start //
				if (user.photoURL !== 'signedup') {
					let utmSource = localStorage.getItem('utm_source')
					let utmCampaign = localStorage.getItem('utm_campaign')
					if (utmSource === 'youtube' && utmCampaign === 'zi8gzag') {
						utmSource = null
						utmCampaign = null
					}

					setTimeout(async () => {
						try {
							const fingerprint = localStorage.getItem('_p_f_')
							const ip = localStorage.getItem('_i_')
							const country = localStorage.getItem('_c_')

							if (newUserToDeleteId.current === user.uid) return

							await userSignedUpFunc({
								userId: user.uid,
								fingerprint,
								ip,
								country,
								username: signupUsername.current,
								characterId: signupCharacterId.current,
								referralCode: signupReferralCode.current,
								emailOptIn: signupEmailOptIn.current,
								utmSource: utmSource,
								utmCampaign: utmCampaign,
							})
							await updateProfile(user, { photoURL: 'signedup' })
						} catch (err) {
							console.log('Error adding username')
							console.error(err)
						}
					}, 5000)
				}
				// new user setup end //

				if (userContext?.id !== user.uid) {
					const savedUser = getSavedUser()

					if (savedUser && savedUser.id === user.uid) {
						setUserContext(savedUser)
					} else {
						setUserContext({
							id: user.uid,
							email: user.email,
							statistics: defaultStatistics,
							xp: 0,
							essence: 0,
							characters: [],
							selectedCharacterId: CHARACTERS[0].characterId,
							dontShowInfoGraphic: [],
							referral: {
								code: '',
								redeemedCode: '',
								subscribed: [],
								signedUp: [],
							},
							isStreamerModeOn: false,
							last5Accuracies: [],
							selectedMapStyle: 'Regular',
							unlockedMapStyles: ['Regular'],
							subscribedTo: [],
						})
					}
					if (!user.emailVerified && !user.displayName) {
						await updateProfile(user, { displayName: 'sentEmail' })
						await sendVerificationEmail()
					}
				}
			} else {
				resetUserState()
			}
		})
		return () => {
			if (authListener.current) {
				authListener.current()
				authListener.current = undefined
			}
		}
	}, [auth, setUserContext])

	const isEmailVerified = () => {
		if (
			auth.currentUser.uid &&
			auth.currentUser.uid !== 'wTHauqSNruQewLr4FfB6k0tVIAg2' &&
			auth.currentUser.uid !== 'PGoBOmzPwmY5O9Sdh4nnGUMyE5p1'
		) {
			if (!auth.currentUser.emailVerified && process.env.NODE_ENV !== 'development') {
				return false
			}
		}
		return true
	}

	return {
		signUpWithEmailAndPassword: signUpWithEmailAndPassword,
		signInWithEmailAndPassword: signInWithEmailAndPw,
		signInWithProvider,
		signOut: logOut,
		sendPasswordResetEmail: sendPwResetEmail,
		sendVerificationEmail,
		isEmailVerified,
	}
}
